'use client';

import { Button } from '@/components/ui/button';
import { Select, SelectContent, SelectGroup, SelectLabel, SelectTrigger, SelectValue, SelectItem, SelectSeparator } from '@/components/ui/select';
import type { ClassifyConfigOption } from '@/lib/configs/adapters/classify';
import { trpc } from '@/lib/trpc';
import { CheckCircle, Download, FileUp, Play } from 'lucide-react';
import React, { useCallback, useMemo, useState } from 'react';
import { demoAccounts, FintechVerticals } from './option';
import { LoadingSpinner } from '@/components/ui/LoadingSpinner';
import { Input } from '@/components/ui/input';
import useResumableUpload from '@/components/hooks/useResumableUpload';
import Link from 'next/link';

export default function Page({ params: { client } }: { params: { client: string } }) {
  const [flowId, setFlowId] = useState<number>();

  const { mutate, isPending: generatingFlow } = trpc.tools.duplicateFlow.useMutation({
    onSuccess: (data) => {
      setFlowId(data);
    },
  });

  const [options, setOptions] = useState<ClassifyConfigOption[]>([]);

  return (
    <div className="flex space-x-6 pt-6 pb-20">
      <div className="w-[67%] border rounded-xl shrink-0 flex overflow-hidden">
        <div className="border-r h-full w-[50%]">
          <Header title="Instructions" />
          <Left onOptionsSelected={(options) => setOptions(options)} />
          <div className="p-5 border-t flex justify-end">
            <Button
              className="bg-purpleLight text-white hover:bg-purple/90 font-semibold"
              onClick={() => mutate({ client: 'mr-tool', flow_config_id: 2957, new_options: options })}
              disabled={options.length < 1}
            >
              Generate plan
            </Button>
          </div>
        </div>
        <div className="h-full w-[50%]">
          <Header title="Plan" />
          <Right flowId={flowId} loading={generatingFlow} options={options} />
        </div>
      </div>
      <div className="min-h-full w-full rounded-xl bg-purple overflow-hidden">
        <Sample flowId={flowId} />
      </div>
    </div>
  );
}

// MARK: Plan
function Right(props: { flowId?: number; loading: boolean; options?: ClassifyConfigOption[] }) {
  const _options = useMemo(() => {
    if (!props.options) return [];
    return props.options.slice(0, 7).map((o) => o.name);
  }, [props.options]);

  return (
    <div className="p-6">
      {props.loading && (
        <div className="flex space-x-2">
          <LoadingSpinner className="text-purpleLight" />
          <span>Generating Plan...</span>
        </div>
      )}
      {props.flowId && (
        <div className="space-y-4">
          <PlanStep index={1} options={['Name', 'Description', 'Website URL']}>
            Selected <b>input data sources</b>
          </PlanStep>
          <PlanStep index={2} options={['Website URL']}>
            Browse company <b>website</b>
          </PlanStep>
          <PlanStep index={3} options={_options}>
            Generate <b>vertical classification</b>
          </PlanStep>
        </div>
      )}
    </div>
  );
}

// MARK: Plan Step
function PlanStep(props: React.PropsWithChildren & { index: number; options: string[] }) {
  return (
    <div>
      <div className="flex space-x-2">
        <div className="bg-purpleLight rounded-full p-1 h-5 w-5 text-[11px] font-bold  text-white flex items-center justify-center">
          <span>{props.index}</span>
        </div>
        <span className="opacity-50 text-sm">{props.children}</span>
      </div>
      <div className="flex flex-wrap ml-7 pt-2">
        {props.options.map((o) => (
          <span key={o} className="text-xs font-medium bg-zinc-400/20 text-zinc-500 rounded-sm px-2 py-1 mb-2 mr-2">
            {o}
          </span>
        ))}
        {props.options.length > 5 && <span className="text-sm opacity-50 underline cursor-pointer">more...</span>}
      </div>
    </div>
  );
}

// MARK: Vertical Selection
function Left(props: { onOptionsSelected: (options: ClassifyConfigOption[]) => void }) {
  const [selectedValue, setSelectedValue] = useState<string>();
  const [state, setState] = useState<'Upload CSV' | 'Uploading...' | 'Uploaded' | string>('Upload CSV');

  const filename = `${1234}-options`;

  const { upload } = useResumableUpload('momentum-supabase-prod-net-new-upload', filename, () => {
    mutate({ client: 'mr-tool', reportId: filename });
  });

  const { mutate } = trpc.tools.parseOptions.useMutation({
    onSuccess: (options) => {
      props.onOptionsSelected(options);
      setState(`Uploaded ${options.length} verticals`);
    },
  });

  return (
    <div className="p-6 pb-7">
      <div className="border bg-landingGrey/40 rounded-md">
        <span className="text-sm py-2 px-3 block h-[124px] opacity-50">Classify this business into verticals using my verticals schema</span>
      </div>
      <span className="text-sm opacity-50 pt-2 block">The instructions for this demo task can&apos;t be edited</span>
      <span className="text-sm font-semibold pt-6 block">Define your verticals</span>
      <div className="space-y-3 flex flex-col items-start pt-3">
        <div className="flex items-center w-full">
          <Select
            onValueChange={(value) => {
              if (value === 'Fintech Verticals') {
                props.onOptionsSelected(FintechVerticals);
              }
              setSelectedValue(value);
            }}
          >
            <SelectTrigger className=" w-[252px] border-zinc-200 ring-0 focus:ring-0 text-black ring-transparent">
              <SelectValue placeholder="Select verticals" className="text-black" />
            </SelectTrigger>
            <SelectContent className="bg-white text-black">
              <SelectGroup className="bg-white">
                <SelectLabel>Pre-defined verticals </SelectLabel>
                {/* <SelectSeparator /> */}
                <SelectItem className="bg-white text-black pl-8 py-2 text-sm" value="Fintech Verticals">
                  Fintech Verticals
                </SelectItem>
              </SelectGroup>
              <SelectSeparator />
              <SelectGroup className="bg-white">
                <SelectLabel>Custom</SelectLabel>
                <SelectItem className="bg-white text-black pl-8 py-2 text-sm" value="csv">
                  <div className="flex items-center">
                    <FileUp className="h-4 w-4 mr-2" />
                    <span>Upload a CSV</span>
                  </div>
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        {selectedValue === 'csv' && (
          <Link
            href={'https://docs.google.com/spreadsheets/d/1SlqFOGnW7nmR0gOjWxA2nzrU30CYWuxDgggoMmCD1y0/edit?gid=0#gid=0'}
            className="ml-0.5 underline text-zinc-400 text-xs mt-2"
            target="_blank"
          >
            Download template
          </Link>
        )}
        {selectedValue === 'csv' && (
          <div
            className="border border-dashed rounded flex items-center justify-center w-full h-[100px] cursor-pointer hover:bg-zinc-100/40 transition-all"
            onClick={() => {
              document.getElementById('import_csv_input_import_accounts')?.click();
            }}
          >
            {state.includes('Uploaded') ? <CheckCircle className="h-4 w-4 mr-2" /> : <FileUp className="h-4 w-4 mr-2" />}
            <span>{state}</span>
          </div>
        )}
        <Input
          type="file"
          name="csv_input"
          id="import_csv_input_import_accounts"
          hidden
          style={{ display: 'none' }}
          onChange={() => {
            const csv = (document.getElementById('import_csv_input_import_accounts') as HTMLInputElement).files?.[0];
            const reader = new FileReader();
            reader.onload = () => {
              if (csv) {
                upload(csv);
                setState('Uploading...');
              }
            };
            reader.readAsText(csv!);
          }}
        />
      </div>
    </div>
  );
}

// MARK: Sample
function Sample(props: { flowId?: number }) {
  const [csvUploaded, setCSVUploaded] = useState(false);
  const [state, setState] = useState<'Upload CSV' | 'Uploading...' | 'Uploaded' | string>('Upload CSV');
  const [selectedValue, setSelectedValue] = useState<string>('Single Account');
  const [accountValue, setAccountValue] = useState<string>();
  const [trackingId, setTrackingId] = useState<number>();
  const [running, setRunning] = useState(false);
  const [loadingState, setLoadingState] = useState('Running...');

  const { upload } = useResumableUpload('momentum-supabase-prod-net-new-upload', `${props.flowId}`, () => {
    setCSVUploaded(true);
    setState('Uploaded accounts');
  });

  const { mutateAsync: dispatchFlow } = trpc.dispatchFlow.useMutation({
    onSuccess: (data) => {},
    onError: () => {
      setRunning(false);
    },
  });

  const { mutate } = trpc.tools.processAccountsCSV.useMutation({
    onMutate: () => {
      setLoadingState('Running...');
    },
    onSuccess: async () => {
      const result = await dispatchFlow({
        client: 'mr-tool',
        configId: props.flowId!,
        priority: 6,
        filter: {
          conditions: [
            {
              type: 'Base',
              column: 'tags',
              operator: '∋',
              value: `tool-${props.flowId}`,
            },
          ],
        },
      });
      setTrackingId(result.tracking_id);
      setTimeout(() => {
        setLoadingState('Processing...');
        setTimeout(() => {
          setLoadingState('Browsing...');
          setTimeout(() => {
            setLoadingState('Generating...');
          }, 11_000);
        }, 2_000);
      }, 2_000);
    },
    onError: () => {
      setRunning(false);
    },
  });

  const { data: trackingInfo } = trpc.tools.getFlowgres.useQuery(trackingId!, {
    enabled: !!trackingId,
    staleTime: 1000 * 3,
    refetchInterval: 3,
  });

  const finished = useMemo(() => {
    if (!trackingInfo) return 0;
    return trackingInfo.complete === trackingInfo.total;
  }, [trackingInfo]);

  const { mutate: downloadResults } = trpc.tools.downloadResults.useMutation({
    onSuccess: (sheetUrl) => {
      window.open(sheetUrl, '_blank', 'noopener,noreferrer');
    },
    onError: (error) => {},
  });

  const handleRun = useCallback(() => {
    setRunning(true);
    if (selectedValue === 'Single Account' && accountValue) {
      mutate({ reportId: props.flowId!.toString(), client: 'mr-tool', accounts: [{ website: accountValue }] });
    }
    if (selectedValue === 'Demo Accounts') {
      mutate({ reportId: props.flowId!.toString(), client: 'mr-tool', accounts: demoAccounts });
    }
    if (selectedValue === 'csv') {
      mutate({ reportId: props.flowId!.toString(), client: 'mr-tool' });
    }
  }, [mutate, accountValue, selectedValue, props.flowId]);

  let runDisabled = true;
  if (selectedValue === 'Single Account' && accountValue?.includes('.') && props.flowId) {
    runDisabled = false;
  }
  if (selectedValue === 'Demo Accounts' && props.flowId) {
    runDisabled = false;
  }
  if (selectedValue === 'csv' && csvUploaded && props.flowId) {
    runDisabled = false;
  }

  return (
    <div className="text-white relative h-full">
      <div className="w-full h-[56px] bg-purpleLight pl-6 flex items-center">
        <h3 className="text-xl font-bold">Run a sample</h3>
      </div>
      <div className="p-6 space-y-3">
        <span className="text-sm font-bold block mb-3">Define your sample scope</span>
        <SampleSelect onValueChange={(value) => setSelectedValue(value)} />
        {selectedValue === 'Single Account' && (
          <Input
            placeholder="Single account (e.g. oracle.com)..."
            className="bg-white text-black focus:ring-0  border-transparent focus:ring-transparent focus-visible:ring-0 focus:border-transparent focus-visible:border-transparent"
            onChange={(e) => {
              setAccountValue(e.target.value);
            }}
            value={accountValue}
          />
        )}
        {selectedValue === 'csv' && (
          <Link
            href={'https://docs.google.com/spreadsheets/d/1Wq8MY-mhZR9criwab9szat90taJgKZTQgcDhXQjt0eM/edit?gid=0#gid=0'}
            className="ml-0.5 underline text-white text-xs pt-2 block"
            target="_blank"
          >
            Download template
          </Link>
        )}
        {selectedValue === 'csv' && (
          <div
            className="border border-dashed border-white/60 rounded flex items-center justify-center w-full h-[100px] cursor-pointer hover:bg-white/10 transition-all"
            onClick={() => {
              document.getElementById('import_accounts')?.click();
            }}
          >
            {state.includes('Uploaded') ? <CheckCircle className="h-4 w-4 mr-2" /> : <FileUp className="h-4 w-4 mr-2" />}
            <span>{state}</span>
          </div>
        )}
        <Input
          type="file"
          name="csv_input"
          id="import_accounts"
          hidden
          style={{ display: 'none' }}
          onChange={() => {
            const csv = (document.getElementById('import_accounts') as HTMLInputElement).files?.[0];
            const reader = new FileReader();
            reader.onload = () => {
              if (csv) {
                upload(csv);
                setState('Uploading...');
              }
            };
            reader.readAsText(csv!);
          }}
        />
      </div>
      <div className="border-t border-white/60 p-6 absolute bottom-0 left-0 right-0 flex justify-end">
        {finished ? (
          <Button className="text-purpleLight bg-white" onClick={() => downloadResults({ client: 'mr-tool', tool_id: props.flowId! })}>
            <Download className="h-4 w-4 mr-2 text-purpleLight" /> Download results
          </Button>
        ) : (
          <div></div>
        )}
        {!finished && running && (
          <Button disabled={true} className="text-purpleLight bg-white">
            <LoadingSpinner className="h-4 w-4 mr-2 text-purpleLight" /> {loadingState}{' '}
            {trackingInfo && trackingInfo.complete > 0 && `(${trackingInfo.complete})`}
          </Button>
        )}
        {!finished && !running && (
          <Button disabled={runDisabled} className="text-purpleLight bg-white" onClick={handleRun}>
            <Play className="h-4 w-4 mr-2 text-purpleLight" /> Run
          </Button>
        )}
      </div>
    </div>
  );
}

function SampleSelect(props: { onValueChange: (value: string) => void }) {
  return (
    <Select onValueChange={props.onValueChange} defaultValue="Single Account">
      <SelectTrigger className=" w-[252px] border-zinc-200 ring-0 focus:ring-0 text-black ring-transparent bg-white">
        <SelectValue placeholder="Select accounts" className="text-black" />
      </SelectTrigger>
      <SelectContent className="bg-white text-black">
        <SelectGroup className="bg-white">
          <SelectLabel>Testing </SelectLabel>
          <SelectItem className="bg-white text-black pl-8 py-2 text-sm" value="Single Account">
            Single Account
          </SelectItem>
        </SelectGroup>
        <SelectGroup className="bg-white">
          <SelectLabel>Pre-defined accounts </SelectLabel>
          <SelectItem className="bg-white text-black pl-8 py-2 text-sm" value="Demo Accounts">
            100 sample accounts
          </SelectItem>
        </SelectGroup>
        <SelectSeparator />
        <SelectGroup className="bg-white">
          <SelectLabel>Custom</SelectLabel>
          <SelectItem className="bg-white text-black pl-8 py-2 text-sm" value="csv">
            <div className="flex items-center">
              <FileUp className="h-4 w-4 mr-2" />
              <span>Upload a CSV</span>
            </div>
          </SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

// MARK: Header
function Header(props: { title: string }) {
  return (
    <div className="w-full h-[56px] bg-landingGrey pl-6 flex items-center">
      <h3 className="text-xl font-bold">{props.title}</h3>
    </div>
  );
}
