export const FintechVerticals = [
  { id: 1, name: 'Digital Banking & Neobanking', definition: 'Online-only banks offering digital services without physical branches' },
  { id: 2, name: 'Payments & Payment Processing', definition: 'Platforms facilitating electronic transactions between parties' },
  { id: 3, name: 'Lending & Credit', definition: 'Services providing loans and credit solutions to consumers and businesses' },
  { id: 4, name: 'Wealth Management & Investment Platforms', definition: 'Digital tools for investing and managing personal wealth' },
  { id: 5, name: 'Insurance Technology', definition: 'Innovative solutions for purchasing and managing insurance policies' },
  { id: 6, name: 'Regulatory Technology', definition: 'Tools helping businesses comply with financial regulations' },
  { id: 7, name: 'Cryptocurrency & Blockchain', definition: 'Platforms dealing with digital currencies and blockchain technology' },
  { id: 8, name: 'Property Technology', definition: 'Tech solutions focused on the real estate industry' },
  { id: 9, name: 'Personal Finance Management', definition: 'Apps that help individuals manage their personal finances' },
  { id: 10, name: 'Crowdfunding & P2P Financing', definition: 'Platforms enabling fundraising from a large number of people' },
  { id: 11, name: 'Cybersecurity in Finance', definition: 'Security solutions protecting financial data and transactions' },
  { id: 12, name: 'Payroll Technology & HR Solutions', definition: 'Services automating payroll and HR functions' },
  { id: 13, name: 'Identity Verification & Authentication', definition: 'Tools for verifying user identities in transactions' },
  { id: 14, name: 'Tax Technology', definition: 'Software simplifying tax preparation and compliance' },
  { id: 15, name: 'Green Finance & Sustainable Fintech', definition: 'Financial services promoting environmental sustainability' },
  { id: 16, name: 'Credit Scoring & Reporting', definition: 'Systems assessing creditworthiness of individuals and businesses' },
  { id: 17, name: 'Financial Education Platforms', definition: 'Resources educating users about financial concepts' },
  { id: 18, name: 'Payment Gateways & Infrastructure', definition: 'Technology enabling secure online payment processing' },
  { id: 19, name: 'Supply Chain Finance', definition: 'Financial solutions optimizing supply chain operations' },
  { id: 20, name: 'Corporate Banking Solutions', definition: 'Services tailored for corporate financial management' },
  { id: 21, name: 'Alternative Finance', definition: 'Non-traditional financial services and lending models' },
  { id: 22, name: 'Financial Marketplaces & Aggregators', definition: 'Platforms comparing financial products and services' },
  { id: 23, name: 'Trade Finance', definition: 'Services facilitating international trade transactions' },
  { id: 24, name: 'Embedded Finance & Banking-as-a-Service', definition: 'Integration of financial services into non-financial platforms' },
  { id: 25, name: 'Financial Data & Analytics Services', definition: 'Tools providing insights from financial data' },
  { id: 26, name: 'Compliance & Risk Management Solutions', definition: 'Services managing regulatory compliance and risk' },
  { id: 27, name: 'Asset Management & Trading Platforms', definition: 'Platforms for trading and managing investment assets' },
  { id: 28, name: 'Mortgage & Real Estate Finance', definition: 'Services related to property financing' },
  { id: 29, name: 'Remittances & Money Transfer Services', definition: 'Platforms enabling cross-border money transfers' },
  { id: 30, name: 'Financial Inclusion Solutions', definition: 'Services providing financial access to underserved populations' },
  { id: 31, name: 'Alternative Currencies & Payment Methods', definition: 'Non-traditional payment options like digital wallets' },
  { id: 32, name: 'Buy Now Pay Later (BNPL)', definition: 'Services allowing consumers to pay in installments' },
  { id: 33, name: 'Creator Economy Platforms', definition: 'Financial tools supporting content creators and freelancers' },
];

export const demoAccounts = [
  { website: 'homewardhealth.com' },
  { website: 'figtech.com' },
  { website: 'dollarpe.com' },
  { website: 'kheslc.com' },
  { website: 'deindefinancial.com' },
  { website: 'myscholarnet.com' },
  { website: 'lendingtower.com' },
  { website: 'capitalnowfunding.com' },
  { website: 'elevatepay.co' },
  { website: 'envelopebudgeting.com' },
  { website: '365connect.com' },
  { website: 'olo.com' },
  { website: 'joinadro.com' },
  { website: 'mojotech.com' },
  { website: 'suttonfunding.com' },
  { website: 'symplelending.com' },
  { website: 'tallied.io' },
  { website: 'getjobber.com' },
  { website: 'womenincrypto.org' },
  { website: 'nelnetinc.com' },
  { website: 'cash.app' },
  { website: 'payscale.com' },
  { website: 'hycu.com' },
  { website: 'solaborate.com' },
  { website: 'ribbit.ai' },
  { website: 'interchecks.com' },
  { website: 'workmade.com' },
  { website: 'lifeq.com' },
  { website: 'protenus.com' },
  { website: 'pionex.us' },
  { website: 'payoutsnetwork.com' },
  { website: 'hippolending.com' },
  { website: 'defynance.com' },
  { website: 'octaneoc.org' },
  { website: 'slingshot.finance' },
  { website: 'taycor.com' },
  { website: 'fogworks.io' },
  { website: 'blockcircle.com' },
  { website: 'advanceamerica.net' },
  { website: 'jelf.org' },
  { website: 'speedyloancorp.net' },
  { website: 'serviceloansouth.com' },
  { website: 'toggle.ai' },
  { website: 'fundmycontract.com' },
  { website: 'payway.com' },
  { website: 'tasksuite.com' },
  { website: 'stellar.org' },
  { website: 'middesk.com' },
  { website: 'mltply.io' },
  { website: 'solartis.com' },
  { website: 'instabase.com' },
  { website: 'blackhawknetwork.com' },
  { website: 'kyriba.com' },
  { website: 'internationalbancard.com' },
  { website: 'h2crypto.io' },
  { website: 'growersedge.com' },
  { website: 'global.id' },
  { website: 'swapspace.co' },
  { website: 'aeliusventure.com' },
  { website: 'agorareal.com' },
  { website: 'charityvest.org' },
  { website: 'stridehealth.com' },
  { website: 'completecare.com' },
  { website: 'cantaloupe.com' },
  { website: 'fusionrms.com' },
  { website: 'xilo.io' },
  { website: 'xsolla.com' },
  { website: 'versatilecredit.com' },
  { website: 'verifone.com' },
  { website: 'ultirisk.com' },
  { website: 'unchained.com' },
  { website: 'upngo.com' },
  { website: 'terminal3.com' },
  { website: 'performantcorp.com' },
  { website: 'paymentez.com' },
  { website: 'payrange.com' },
  { website: 'pushpay.com' },
  { website: 'okyapp.com' },
  { website: 'linked2pay.com' },
  { website: 'ihealthhome.com' },
  { website: 'khealth.com' },
  { website: 'chippercash.com' },
  { website: 'spave.io' },
  { website: 'clickfunnels.com' },
  { website: 'joineasyhealth.com' },
  { website: 'balto.ai' },
  { website: 'afficiency.com' },
  { website: 'cryptonmobile.com' },
  { website: 'momentumcardbalance.com' },
  { website: 'bluepenguin.com' },
  { website: 'withcherry.com' },
  { website: 'fisecal.com' },
  { website: 'goalry.com' },
  { website: 'boldpenguin.com' },
  { website: 'canopyservicing.com' },
  { website: 'jasper.ai' },
  { website: 'atlascard.com' },
  { website: 'atob.com' },
  { website: 'livedatatechnologies.com' },
];
